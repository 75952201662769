<template>
  <div class="footer-news-latter">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="news-letter-filed-wrap">
            <label for="" class="semiBold-15">{{
              $t("newsletter_subscriptions")
            }}</label>
            <div class="news-letter-input-filed c-input-item">
              <input type="email" name="" id="" class="c-input-filed" :placeholder="$t('email_placeholder')"
                v-model="email" />
              <button @click="subscribe(email)" class="c-btn c-btn-primary regular-12">
                {{ $t("subscribe_newsletter") }}
              </button>
              <!-- <small class="form-error w-100">Dit veld is verplicht</small> -->
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="footer-social" v-if="typeof socialMediaSlider == 'object'">
            <div>
              <label class="semiBold-15 mb-0">{{ $t("follow_us") }}: </label>
              <cmsBlockSimple v-if="typeof socialMedia == 'object'" :identifier="socialMedia.identifier" />
            </div>
            <div v-if="typeof socialMediaSlider == 'object'">
              <ul class="social-media-links">
                <li v-for="(image, index) in socialMediaSlider.slides" :key="index">
                  <b-link class="img" :href="image.link" target="_blank">
                    <img :src="image.media.url" :alt="image.title" class="social-icons" />
                  </b-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import User from "@storefront/core/modules/user/mixins";
import cmsBlockSimple from "@/esf_antarctica_lid/core/components/common/BlockSimple.vue";

export default {
  components: {
    cmsBlockSimple,
  },
  mixins: [User],
  data() {
    return { email: "" };
  },
  computed: {
    socialMedia() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "social_plateform"
      );
    },

    socialMediaSlider() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "social_media_slider"
      );
    }
  },
};
</script>

<style></style>

<template>
  <div id="app" class="lid" :class="{ overlay: isOverlay }">
    <header-wrapper></header-wrapper>
    <router-view />
    <footer-wrapper></footer-wrapper>
    <ClientOnly>
      <at-cookies></at-cookies>
    </ClientOnly>
    <!-- <CompareButton></CompareButton> -->

    <BackToTop></BackToTop>
    <b-modal v-model="modalVisible" @hidden="resetModal">
      {{ modalContent }}
    </b-modal>
  </div>
</template>
<script>
import config from "@config";
import HeaderWrapper from "@/esf_antarctica_lid/core/components/header/HeaderWrapper.vue";
import FooterWrapper from "@/esf_antarctica_lid/core/components/footer/FooterWrapper.vue";
import { isServer } from "@storefront/core/helpers";
import { Logger } from "@storefront/core/lib/logger";
import AtCookies from "@/base/core/components/cookies/Cookies.vue";
import ClientOnly from "vue-client-only";
// import CompareButton from "@/esf_antarctica_lid/core/components/common/CompareButton.vue";
import BackToTop from "@/base/core/components/common/BackToTop.vue";

const contentList = [
  "side-menu-cms",
  "footer_menu_information",
  "footer_menu_service",
  "footer_menu_quick_links",
  "footer_contact",
  "order_today_tomorrow_at_home",
  "free_shipping",
  "our_advantages",
  "discount_header",
  "home_pictures",
  "header_seperator_title",
  "contact_page_block",
  "merken",
  "usp_header",
  "usp_home_block",
  "general_info_footer",
  "categories_footer",
  "customer_service_page",
  "footer_usp_1",
  "footer_usp_2",
  "footer_usp_3",
  "footer_usp_4",
  "social_plateform",
  "conatct_map",
  "header_menu_static",
  "home_content",
];
const sliders = [
  "home",
  "home_gallery",
  "payment_providers",
  "delivery_partners",
  "informative_slider",
  "home_stripe_slider",
  "social_media_slider",
];

export default {
  name: "Home",
  metaInfo() {
    return {
      htmlAttrs: { lang: this.$i18n.locale },
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
      ],
      titleTemplate: "%s | " + config.app_name,
    };
  },

  components: {
    HeaderWrapper,
    FooterWrapper,
    AtCookies,
    ClientOnly,
    // CompareButton,
    BackToTop,
  },

  async serverPrefetch() {
    this.$store.dispatch("forms/loadForms");
    this.$store.dispatch("faq/load");
    await this.$store.dispatch("messages/loadGeneralMsg");
    // await this.$store.dispatch("product/loadBrandSlider");
    await this.$store.dispatch("menu/loadMenu");
    await this.$store.dispatch("user/loadCountries");
    await this.$store.dispatch("product/productReviewRatingsMetadata");

    // await this.$store.dispatch("stores/load");
    await this.$store.dispatch("home/loadBundles");

    await this.$store.dispatch("home/loadNewInRange");
    await this.$store.dispatch("home/loadInActionProducts");
    await this.$store.dispatch("home/loadBestSellers");

    // await this.$store.dispatch("blog/load");
    await this.$store.dispatch("cmsPage/loadDefault", {
      id: "home",
    });
    await this.$store.dispatch("sliders/multiple", {
      key: "identifier",
      value: sliders,
    });
    await this.$store.dispatch("cmsBlock/multiple", {
      key: "identifier",
      value: contentList,
    });
  },

  created() {
    if (!isServer) {
      Logger.debug(
        "wesiteCookie",
        "App.vue",
        this.$cookies.get("websiteCookie")
      )();
      if (this.$cookies.get("websiteCookie") == "true") {
        this.$gtm.enable(true);
        this.$gtm.debug(true);
        Logger.debug("gtm1", "App.vue", this.$gtm.enabled())();
      }
      if (config.uaDisabled) {
        this.$gtm.uaDisabled = true;
      } else {
        this.$gtm.uaDisabled = false;
      }
      if (config.ga4enabled) {
        this.$gtm.ga4Enabled = true;
      } else {
        this.$gtm.ga4Enabled = false;
      }
    }
  },
  async mounted() {
    await this.$store.dispatch("user/loadUser");
    if (this.$store.getters["cart/getCartIsLoaded"] == false) {
      await this.$store.dispatch("cart/loadCart");
    }
  },

  computed: {
    isOverlay() {
      return this.$store.getters["home/getOverlay"];
    },
    currentTemplate() {
      return config.template.name;
    },
  },
  watch: {
    "$store.state.messages.msgItems": async function () {
      Logger.debug("App", "watch", "IsCalled")();
      const msgs = await this.$store.dispatch("messages/giveLastMessages");
      Logger.debug("App", "watch", typeof msgs)();
      Logger.debug("App", "watch", msgs)();
      msgs.forEach(function (msg) {
        Logger.debug("app", "watch", msg)();
        this.makeToast(msg);
      }, this);
    },
    "$store.state.user.isLoggedIn": async function (isLoggedIn) {
      if (isLoggedIn == true) {
        this.$store.dispatch("wishlist/loadLoginWishlist");
      } else {
        this.$store.dispatch("wishlist/loadWishlist");
      }
    },
  },

  data() {
    return {
      modalVisible: false,
      modalContent: "",
    };
  },

  methods: {
    openModal(msg) {
      this.modalContent = msg.text;
      this.modalVisible = true;
    },

    resetModal() {
      this.modalContent = "";
      this.modalVisible = false;
    },

    // Aangepaste makeToast-methode die de modal opent
    makeToast(msg) {
      this.openModal(msg);
    },

    // makeToast(msg) {
    //   this.$bvToast.toast(msg.text, {
    //     //title: msg.title,
    //     variant: msg.type,
    //     autoHideDelay: 10000,
    //     toaster: "b-toaster-top-right",
    //     solid: true,
    //     append: true,
    //   });
    // },
  },
};
</script>

<style lang="scss"></style>


<template>
  <div class="middle-header">
    <div class="container">
      <div class="row logo-row">
        <!-- <div class="col-12 col-md-4 d-none d-sm-block">
          <div class="navbar-brand">
            <router-link :to="{ name: 'home' }">
               <img :src="imgUrl('brand.png')" alt="brand logo" />
            </router-link>
          </div>
        </div> -->
        <div class="col-12 col-md-12">
          <div class="middle-right">
            <div class="header-fade-slider">
              <div class="header-slider-wrap">
                <ClientOnly>
                  <VueSlickCarousel :arrows="false" :dots="false" :slidesToShow="3" :autoplay="true" :responsive="[
                    {
                      breakpoint: 1199,
                      settings: {
                        slidesToShow: 2,
                      },
                    },
                    {
                      breakpoint: 767,
                      settings: {
                        slidesToShow: 2,
                      },
                    },
                    {
                      breakpoint: 540,
                      settings: {
                        slidesToShow: 1,
                        autoplay: true,
                      },
                    },
                  ]">
                    <div class="cms-block-content" v-for="(slide, index) in informativeSlider.slides" :key="index">
                      <img v-if="slide.media" :src="slide.media.url" alt="Slide Image" class="slide-media">
                      <div v-html="slide.description"></div>
                    </div>
                  </VueSlickCarousel>
                </ClientOnly>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientOnly from "vue-client-only";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
// import cmsBlockSimple from "@/base/core/components/common/BlockSimple.vue";
import getImageUrl from "@/base/helpers/getImageUrl.js";

export default {
  components: {
    VueSlickCarousel,
    // cmsBlockSimple,
    ClientOnly,
  },

  data() {
    return {
      carouselConf: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,

        responsive: [
          {
            breakpoint: 600,
            settings: {
              autoplay: true,
              fade: true,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
            },
          },
        ],
      },
    };
  },
  computed: {
    informativeSlider() {
      const data =
        this.$store.getters["sliders/getSliderByIdentifier"](
          "informative_slider"
        );
      return data;
    },
  },
  methods: {
    imgUrl(fileName) {
      return getImageUrl(fileName);
    },
  },
};
</script>


<style></style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"middle-header"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row logo-row"},[_c('div',{staticClass:"col-12 col-md-12"},[_c('div',{staticClass:"middle-right"},[_c('div',{staticClass:"header-fade-slider"},[_c('div',{staticClass:"header-slider-wrap"},[_c('ClientOnly',[_c('VueSlickCarousel',{attrs:{"arrows":false,"dots":false,"slidesToShow":3,"autoplay":true,"responsive":[
                  {
                    breakpoint: 1199,
                    settings: {
                      slidesToShow: 2,
                    },
                  },
                  {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: 2,
                    },
                  },
                  {
                    breakpoint: 540,
                    settings: {
                      slidesToShow: 1,
                      autoplay: true,
                    },
                  } ]}},_vm._l((_vm.informativeSlider.slides),function(slide,index){return _c('div',{key:index,staticClass:"cms-block-content"},[(slide.media)?_c('img',{staticClass:"slide-media",attrs:{"src":slide.media.url,"alt":"Slide Image"}}):_vm._e(),_c('div',{domProps:{"innerHTML":_vm._s(slide.description)}})])}),0)],1)],1)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="m-link-wrap">
    <!-- <router-link :to="{ name: 'home' }" class="d-sm-none logo_mobile">
      <img :src="imgUrl('brand.png')" alt="" />
    </router-link> -->
    <div class="search-btn-m" @click="$emit('show-search-m')">
      <b-icon icon="search"></b-icon>
    </div>
    <ul class="icon-link-wrap" :class="{ 'not-login': !isLoggedIn, login: isLoggedIn }">
      <li v-for="icon of icons" :key="icon.iconNumber" class="top-menu" @click.prevent="onClick(icon)"
        @mouseenter="onOver(icon)" @mouseleave="onLeave(icon)">
        <b-link v-if="icon.isEnabled" :to="icon.link">
          <!-- v-b-toggle="`topIconsCollapse` + showType + icon.iconNumber" -->
          <span class="icon">
            <ClientOnly>
              <i v-if="icon.isLinearIcon" class="fas" :class="icon.iconName"></i>
              <div class="img" v-else>
                <b-img :src="icon.iconName" width="25" height="25" :alt="icon.text" fluid></b-img>
              </div>
            </ClientOnly>
            <b-badge v-if="icon.hasBadge" :variant="icon.badgeColor">{{
              icon.badgeValue
            }}</b-badge>
          </span>
          <span>{{ icon.text }}</span>
        </b-link>
        <HeaderIconsDropdownMenu v-if="icon.hasDropdown &&
          icon.menuId == 'account' &&
          icon.isEnabled == true
          " :icon="icon" :showType="showType" />
        <HeaderIconsDropdownCart v-if="icon.hasDropdown && icon.menuId == 'cart' && icon.isEnabled == true
          " :icon="icon" :showType="showType" />
        <HeaderIconsDropdown v-if="icon.hasDropdown &&
          icon.menuId == 'wishList' &&
          icon.isEnabled == true
          " :icon="icon" :showType="showType" />
      </li>
    </ul>
  </div>
</template>
<script>
//import { mapGetters } from "vuex";
import HeaderIconsDropdown from "@/esf_antarctica_lid/core/components/header/HeaderIconsDropdown.vue";
import HeaderIconsDropdownCart from "@/base/core/components/header/HeaderIconsDropdownCart.vue";
import HeaderIconsDropdownMenu from "@/base/core/components/header/HeaderIconsDropdownMenu.vue";
//import wishListAdapter from "@/base/helpers/adapters/wishListAdapter";
import Cart from "@storefront/core/modules/cart/mixins";
// import infoIcon from "@/base/assets/imgs/icon-info.png";
import ClientOnly from "vue-client-only";
import getImageUrl from "@/base/helpers/getImageUrl.js";

export default {
  name: "HeaderTopIcons",
  mixins: [Cart],
  components: {
    HeaderIconsDropdown,
    ClientOnly,
    HeaderIconsDropdownCart,
    HeaderIconsDropdownMenu,
  },
  methods: {
    onClick(icon) {
      if (window.innerWidth <= 991) {
        this.$root.$emit(
          "bv::toggle::collapse",
          `topIconsCollapse` + this.showType + icon.iconNumber
        );
        setTimeout(() => {
          this.$root.$emit(
            "bv::toggle::collapse",
            `topIconsCollapse` + this.showType + icon.iconNumber
          );
        }, 5000);
      }
    },
    onOver(icon) {
      if (window.innerWidth > 991) {
        this.$root.$emit(
          "bv::toggle::collapse",
          `topIconsCollapse` + this.showType + icon.iconNumber
        );
      }
    },
    onLeave(icon) {
      if (window.innerWidth > 991) {
        this.$root.$emit(
          "bv::toggle::collapse",
          `topIconsCollapse` + this.showType + icon.iconNumber
        );
      }
    },
    imgUrl(fileName) {
      return getImageUrl(fileName);
    },
  },
  props: {
    showType: { type: String },
  },
  computed: {
    wishlistItems() {
      return this.$store.getters['wishlist/getWishItems'];
    },
    wishlistCount() {
      return this.$store.getters['wishlist/getWishQuantity'];
    },
    icons() {
      return [
        // {
        //   iconNumber: 1,
        //   isLinearIcon: true,
        //   hasBadge: false,
        //   iconName: "lnr-map-marker",
        //   text: "Onze winkels",
        //   hasDropdown: false,
        //   link: "/winkels",
        //   isEnabled: true,
        // },
        {
          iconNumber: 2,
          isLinearIcon: true,
          hasBadge: false,
          iconName: "fa-phone-alt",
          text: "Klantenservice",
          hasDropdown: false,
          link: "/klantenservice",
          isEnabled: true,
        },

        {
          iconNumber: 4,
          isLinearIcon: true,
          hasBadge: true,
          badgeValue: this.wishlistCount,
          badgeColor: "success",
          iconName: "fa-heart",
          text: "Wensenlijst",
          hasDropdown: true,
          menuId: "wishList",
          isEnabled: true,
          dropHeading: this.$t("items_in_wishlist", {
            numberOf: this.wishlistCount,
          }),
          dropItems: this.wishlistItems,
        },
        {
          iconNumber: 5,
          isLinearIcon: true,
          hasBadge: false,
          iconName: "fa-user-alt",
          text: this.$t("login"),
          hasDropdown: false,
          link: "/login",
          isEnabled: !this.isLoggedIn,
        },
        {
          iconNumber: 6,
          isLinearIcon: true,
          hasBadge: false,
          iconName: "fa-user-alt",
          text: this.$t("account"),
          menuId: "account",
          hasDropdown: true,
          isEnabled: this.isLoggedIn,
        },
        {
          iconNumber: 3,
          isLinearIcon: true,
          hasBadge: true,
          badgeValue: this.cartCount,
          badgeColor: "success",
          iconName: "fa-shopping-basket",
          text: this.$t("shopping_basket"),
          isEnabled: true,
          hasDropdown: true,
          dropHeading: this.$t("items_in_cart", { numberOf: this.cartCount }),
          dropItems: this.cartItems,
          menuId: "cart",
          cartFooter: {
            totalPrice: this.cartSubTotalPrice,
            btnText: this.$t("goto_checkout"),
            btnVariant: "success",
          },
          link: "#",
        },
      ];
    },
  },
  mounted() {
    this.$store.dispatch('wishlist/loadWishlist');
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <header class="header" id="Header">
      <at-header-top @ham-toggle="toggleHamView"></at-header-top>
      <at-header-text></at-header-text>
      <at-header-middle ></at-header-middle>
      <!-- <at-header-usp v-if="isCheckoutPage"></at-header-usp> -->
      <Navbar v-if="!isCheckoutPage" />
      <div :class="{ 'overlay-menu': isHamVisible }" @click="isHamVisible = false"></div>
    </header>
    <div :class="{ 'slide-fade': isHamVisible }">
      <SidebarMenu @ham-toggle="toggleHamView"></SidebarMenu>
    </div>
  </div>
</template>

<script>
import AtHeaderTop from "@/esf_antarctica_lid/core/components/header/HeaderTop.vue";
import AtHeaderText from "@/base/core/components/header/HeaderText.vue";
import AtHeaderMiddle from "@/esf_antarctica_lid/core/components/header/HeaderMiddle.vue";
// import AtHeaderUsp from "@/esf_antarctica_lid/core/components/header/HeaderUsp.vue";
import Navbar from "@/esf_antarctica_lid/core/components/header/NavBar.vue";
import SidebarMenu from "@/esf_antarctica_lid/core/components/sidebar/SidebarMenu.vue";

export default {
  components: {
    AtHeaderTop,
    AtHeaderText,
    AtHeaderMiddle,
    // AtHeaderUsp,
    Navbar,
    SidebarMenu,
  },

  computed: {
    isCheckoutPage() {
      return this.$route.name === 'checkout';
    }
  },

  data() {
    return {
      isHamVisible: false,
    };
  },

  methods: {
    toggleHamView() {
      // this.$store.commit("home/updateOverlay");
      this.isHamVisible = !this.isHamVisible;
    },
  },
};
</script>

<style></style>
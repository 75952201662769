<template>
  <ClientOnly>
    <v-runtime-template
      :class="['cms-block-content', { container: sync }]"
      v-if="getCmsData"
      :template="`<div>` + getCmsData.content + `</div>`"
    ></v-runtime-template>
  </ClientOnly>
</template>

<script>
import { isServer } from "@storefront/core/helpers";
import ClientOnly from "vue-client-only";
import VRuntimeTemplate from "v-runtime-template";

export default {
  name: "CmsBlockSimple",
  components: { ClientOnly, VRuntimeTemplate },
  props: {
    id: {
      type: Number,
      default: null,
      required: false,
    },
    identifier: {
      type: String,
      default: null,
      required: false,
    },
    sync: {
      type: Boolean,
      default: false,
      required: false,
    },
    showtitle: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  serverPrefetch() {
    return this.fetchCmsBlock();
  },
  created() {
    if (!isServer) {
      this.fetchCmsBlock();
    }
  },
  methods: {
    fetchCmsBlock() {
      let queryKey = "";
      let queryValue = "";
      if (this.id) {
        queryKey = "id";
        queryValue = this.id;
      } else if (this.identifier) {
        queryKey = "identifier";
        queryValue = this.identifier;
      }
      if (queryKey && queryValue) {
        return this.$store.dispatch("cmsBlock/single", {
          key: queryKey,
          value: queryValue,
        });
      }
    },
  },
  computed: {
    getCmsData() {
      if (this.id) {
        return this.$store.getters[`cmsBlock/getCmsBlockById`](this.id);
      } else if (this.identifier) {
        return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
          this.identifier
        );
      }
      return null;
    },
  },
};
</script>
<style lang="scss">
.cms-block-content {
  p {
    margin-bottom: 0px;
  }
  strong,
  b {
    font-weight: 500;
  }
}
</style>

<template>
  <b-collapse :id="'' + collapseId" class="sub-menu" v-model="visible">
    <ul v-for="menu in menuArray" :key="'menu' + menuArray.indexOf(menu)">
      <li v-for="menuItem in menu" :key="menuItem.id">
        <!-- {{ menu }} -->
        <div class="sub-sub-menu">
          <b-link v-on:click="closeInternal" :to="menuItem.linkTo">
            {{ menuItem.name }}
          </b-link>
          <ClientOnly>
            <div
              class="icon"
              @click.stop="onClickItem(menuItem.id)"
              :ref="`sub${menuItem.id}`"
              :class="{ 'arrow-down': menuItem.isOpen }"
              v-if="menuItem.dropMenu && menuItem.dropMenu.length"
            >
              <i class="fas fa-chevron-right"></i>
            </div>
          </ClientOnly>
        </div>
        <!-- tmp{{ menuItem.dropMenu }} -->
        <b-collapse
          :id="'' + menuItem.id"
          class="sub-sub-sub-menu"
          v-model="menuItem.isOpen"
        >
          <ul>
            <li v-for="subItem in menuItem.dropMenu" :key="subItem.id">
              <!-- {{ subItem }} -->

              <b-link
                v-on:click="closeSubInternal(menuItem.id)"
                :to="`/${subItem.url_path}`"
                >{{ subItem.name }}</b-link
              >
            </li>
          </ul>
        </b-collapse>
      </li>
    </ul>
  </b-collapse>
</template>

<script>
//import { Logger } from "@storefront/core/lib/logger";
import ClientOnly from "vue-client-only";
export default {
  name: "SidebarSubMenu",
  props: ["menuArray", "collapseId", "modelValue", "id", "isHam"],
  components: {
    ClientOnly,
  },
  data: () => ({
    //visible: false,
    me: this,
  }),

  computed: {
    visible: {
      get() {
        return this.$store.getters["menu/getHamMenuItemState"](this.id).state;
      },
      set(val) {
        this.$store.commit("menu/updateHamMenuState", {
          id: this.id,
          state: val,
        });
      },
    },
  },
  methods: {
    // close(e) {
    //   if (!this.$el.parentElement.contains(e.target)) {
    //     this.visible = false;
    //     //this.$store.commit("menu/setNavBar", false);
    //   }
    // },
    closeInternal() {
      this.visible = false;
      this.$emit("ham-toggle");
      // this.$store.commit("menu/setNavBar", false);
    },
    closeSubInternal(id) {
      let clickedElem = this.menuArray[0].find((el) => {
        return el.id === id;
      });
      if (clickedElem["isOpen"] !== undefined) {
        clickedElem.isOpen = !clickedElem.isOpen;
      }
      this.visible = false;
      this.$emit("ham-toggle");
    },
    onClickItem(id) {
      // >>>>>>>>>>
      //
      // const elementClasses = this.$refs["sub" + id][0].className.split(" ");
      // const isDownClass = elementClasses.find((el) => el === "arrow-down");
      // if (isDownClass === undefined) {
      //   this.$refs["sub" + id][0].classList.add("arrow-down");
      // } else {
      //   this.$refs["sub" + id][0].classList.remove("arrow-down");
      // }
      //
      // >>>>>>>>>>

      // this.$store.commit("menu/updateMenuState", { id: id, state: true });
      // let clickedElem = this.menuArray[0].find((el) => {
      //   return el.id === id;
      // });
      // if (clickedElem["isOpen"] !== undefined) {
      //   clickedElem.isOpen = !clickedElem.isOpen;
      // } else {
      //   clickedElem.isOpen = true;
      // }

      this.menuArray[0].forEach((element) => {
        if (element.id === id) {
          if (element["isOpen"] !== undefined) {
            element.isOpen = !element.isOpen;
          } else {
            element.isOpen = true;
          }
        } else {
          element.isOpen = false;
        }
      });

      // const vis = this.$store.getters["menu/getMenuItemState"](id);
      // Logger.debug("onToggle id", "Navbar", vis.id)();
      // Logger.debug("onToggle", "Navbar", vis.state)();

      // if (vis.state == false) {
      //   this.$store.commit("menu/updateMenuState", { id: id, state: true });
      // } else {
      //   this.$store.commit("menu/updateMenuState", { id: id, state: false });
      // }
    },
  },
  // mounted() {
  //   document.addEventListener("click", this.close);
  // },
  // destroyed() {
  //   document.removeEventListener("click", this.close);
  // },
};
</script>

<style lang="scss" scoped>
</style>

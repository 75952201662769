<template>
  <div class="side-menu side-effect">
    <ClientOnly>
      <b-link @click="$emit('ham-toggle')" class="menu-close"
        ><i class="fas fa-times"></i
      ></b-link>
    </ClientOnly>
    <ul class="nav-menu">
      <li class="nav-item">
        <span class="nav-link"
          ><b-link
            @click="
              $router.push({ name: 'home' });
              $emit('ham-toggle');
            "
            >{{ $t("home") }}</b-link
          ></span
        >
      </li>
      <li class="nav-item" v-for="navItem of navItems" :key="navItem.id">
        <div class="nav-link" @click="onToggle(navItem.id)">
          <b-link to="#" @click.stop="onClick(navItem.id, navItem.linkTo)">{{
            navItem.itemName
          }}</b-link>
          <ClientOnly>
            <div
              class="icon"
              :class="{
                'arrow-down': $store.getters['menu/getHamMenuItemState'](
                  navItem.id
                ).state,
              }"
              @click.stop="onOver(navItem.id)"
              :ref="`cat${navItem.id}`"
              v-if="navItem.dropMenu && navItem.dropMenu.length"
            >
              <i class="fas fa-chevron-right"></i>
            </div>
          </ClientOnly>
        </div>
        <SidebarSubMenu
          v-if="navItem.hasDropMenu"
          :menuArray="navItem.dropMenu"
          :collapseId="'submenu-ham' + navItem.id"
          :id="'' + navItem.id"
          :isHam="true"
          @ham-toggle="$emit('ham-toggle')"
        />
      </li>
      <!-- <li class="nav-item">
        <span class="nav-link"
          ><b-link
            @click="
              $router.push({ name: 'over' });
              $emit('ham-toggle');
            "
            >{{ $t("Over Ons") }}</b-link
          ></span
        >
      </li>
      <li class="nav-item">
        <span class="nav-link"
          ><b-link
            @click="
              $router.push({ name: 'contact' });
              $emit('ham-toggle');
            "
            >{{ $t("Contact") }}</b-link
          ></span
        >
      </li> -->
      <!-- <li class="nav-item">
        <b-link
          @click="
            $router.push('/sale');
            $emit('ham-toggle');
          "
          >{{ $t("sale") }}</b-link
        >
      </li> -->
    </ul>
  </div>
</template>

<script>
import { Logger } from "@storefront/core/lib/logger";
import SidebarSubMenu from "@/esf_antarctica_lid/core/components/sidebar/SidebarSubMenu.vue";
import ClientOnly from "vue-client-only";
export default {
  components: {
    SidebarSubMenu,
    ClientOnly,
  },

  computed: {
    navItems() {
      let menuItems = this.$store.getters["menu/getMenuItems"].slice(0, 6);
      menuItems.forEach(() => {
        // el.isOpen = false;
      });

      return menuItems;
    },
  },

  methods: {
    onOver(id) {
      const vis = this.$store.getters["menu/getHamMenuItemState"](id);
      Logger.debug("onToggle id", "Navbar", vis.id)();
      Logger.debug("onToggle", "Navbar", vis.state)();

      if (vis.state == false) {
        this.$store.commit("menu/updateHamMenuState", {
          id: id,
          state: true,
        });
      } else {
        this.$store.commit("menu/updateHamMenuState", {
          id: id,
          state: false,
        });
      }
    },

    tmp(id) {
     
      return this.$store.getters["menu/getHamMenuItemState"](id).state;
    },

    onLeave(id) {
      this.$store.commit("menu/updateHamMenuState", { id: id, state: false });
    },

    onToggle(id) {
      const vis = this.$store.getters["menu/getHamMenuItemState"](id);
      Logger.debug("onToggle id", "Navbar", vis.id)();
      Logger.debug("onToggle", "Navbar", vis.state)();

      if (vis.state == false) {
        this.$store.commit("menu/updateHamMenuState", {
          id: id,
          state: true,
        });
      } else {
        this.$store.commit("menu/updateHamMenuState", {
          id: id,
          state: false,
        });
      }
    },
    onClick(id, route) {
      Logger.debug("onClick", "navbarContent", id)();
      Logger.debug("onClick", "navbarContent", route)();
      Logger.debug(
        "onClick menu item state",
        "navbarContent",
        this.$store.getters["menu/getMenuItemState"](id).state
      )();
      this.$store.commit("menu/updateMenuState", { id: id, state: false });
      Logger.debug(
        "onClick menu item state",
        "navbarContent",
        this.$store.getters["menu/getMenuItemState"](id).state
      )();
      this.$store.commit("menu/setNavBar", false);

      this.$router.push(route);
      this.$emit("ham-toggle");
    },

    getCurrentMenuItemState(id) {
      let clickedElem = this.navItems.find((el) => {
        return el.id === id;
      });
      if (clickedElem && clickedElem.isOpen) {
        return clickedElem.isOpen;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
</style>
<template>
  <footer id="Footer" class="footer">
    <at-footer-news-latter></at-footer-news-latter>
    <at-footer-contact v-if="!isCheckoutPage"></at-footer-contact>
    <!-- <at-footer-copy-right></at-footer-copy-right> -->
  </footer>
</template>

<script>
import AtFooterNewsLatter from "./FooterNewsLatter.vue";
import AtFooterContact from "./FooterContact.vue";
// import AtFooterCopyRight from "./FooterCopyRight.vue";

export default {
  components: {
    AtFooterNewsLatter,
    AtFooterContact,
    // AtFooterCopyRight,
  },
  computed: {
    isCheckoutPage(){
      return this.$route.name === 'checkout';
    }
  },
};
</script>

<style>
</style>